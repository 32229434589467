import React from 'react';
import './index.scss';
import {  Grid, Form, Header } from 'semantic-ui-react';
import { Controller } from "react-hook-form";
import { Button } from 'dyl-components';


const OfficeViewCallResults = ({ 
    control,
    callResultOptions,
    callTagOptions,
    relatedToOptions,
    onSave,
    onSaveDisabled = false,
    callResultsDisabled = false,
    callTagDisabled = false,
    relatedToDisabled = false,
 }) => {
    
    return (
        <Grid className="OfficeViewCallResults" columns="equal">
            <Grid.Row>
                <Grid.Column>
                    <Controller
                        name={'call_result'}
                        control={control}
                        render={({ field: { name, value, onChange } }) => (
                            <Form.Select
                                name={name}
                                value={value}
                                search
                                onChange={(_, { value }) => {
                                    onChange({
                                        target: { name, value },
                                    });
                                }}
                                placeholder="Call result"
                                selectOnBlur={false}
                                options={callResultOptions}
                                fluid
                                disabled={callResultsDisabled}
                            />
                        )}
                    />
                </Grid.Column>
                <Grid.Column>
                    <Controller
                        name={'call_tags'}
                        control={control}
                        render={({ field: { name, value, onChange } }) => (
                            <Form.Select
                                name={name}
                                value={value}
                                search
                                onChange={(_, { value }) => {
                                    onChange({
                                        target: { name, value },
                                    });
                                }}
                                placeholder="Call tags"
                                selectOnBlur={false}
                                options={callTagOptions}
                                fluid
                                disabled={callTagDisabled}
                            />
                        )}
                    />
                </Grid.Column>
                <Grid.Column>
                    <Controller
                        name={'related_to'}
                        control={control}
                        render={({ field: { name, value, onChange } }) => (
                            <Form.Select
                                name={name}
                                value={value}
                                search
                                onChange={(_, { value }) => {
                                    onChange({
                                        target: { name, value },
                                    });
                                }}
                                placeholder="Related to"
                                selectOnBlur={false}
                                options={relatedToOptions}
                                fluid
                                disabled={relatedToDisabled}
                            />
                        )}
                    />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column width={14}>
                    <Controller
                        name='call_notes'
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <>
                                <Header as="h6" style={{marginBottom: 8}}>Call Notes</Header>
                                <Form.TextArea
                                    onChange={e=>{
                                        onChange(e);
                                    }}
                                    value={value}
                                    rows={4}
                                />
                            </>
                        )}
                    />
                </Grid.Column>
                <Grid.Column verticalAlign='bottom' style={{marginBottom: 5}}>
                    <Button fluid onClick={onSave} disabled={onSaveDisabled}>Save</Button>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
};

export default OfficeViewCallResults;