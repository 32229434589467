import React from 'react';
import { PhoneButton } from 'dyl-components';
import {  Grid } from 'semantic-ui-react';

const OfficeViewActionButtons = ({ 
    hangup,
    hangupDisabled = false,
    vmDrop,
    vmDropDisabled = false,
    transfer,
    transferDisabled = false,
    hold,
    holdDisabled = false,
    mute,
    muteDisabled = false,
 }) => {
    
    return (
        <Grid className="OfficeViewActionButtons" columns="equal">
            <Grid.Row>
                <Grid.Column>
                    <PhoneButton text="Hang Up" icon="fa-solid fa-phone-hangup" iconColor="red" onClick={hangup} disabled={hangupDisabled}/>
                </Grid.Column>
                <Grid.Column>
                    <PhoneButton text="VM Drop" icon="fa-solid fa-voicemail" iconColor="blue" onClick={vmDrop} disabled={vmDropDisabled} />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <PhoneButton text="Transfer" icon="fa-solid fa-right-left" iconColor="blue" onClick={transfer} disabled={transferDisabled} />
                </Grid.Column>
                <Grid.Column>
                    <PhoneButton text="Hold" icon="fa-solid fa-pause" iconColor="grey" onClick={hold} disabled={holdDisabled} />
                </Grid.Column>
                <Grid.Column>
                    <PhoneButton text="Mute" icon="fa-solid fa-microphone" iconColor="grey" onClick={mute} disabled={muteDisabled} />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
};

export default OfficeViewActionButtons;