import { Centrifuge } from 'centrifuge';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import routes from 'actions/routes';
import basePath from 'actions/basePath';
import notificationsActions from 'actions/notifications';
import permissionsActions from 'actions/permissions';
import userActions from 'actions/user';
import authActions from 'actions/auth/auth';
import roleActions from 'actions/role';
import { NotificationInfo } from 'dyl-components';
import axiosInstance from 'actions/axiosInstance';
import NotificationPopupModals from "shared/modals/NotificationPopUpModals";
import SendEmailModal from "shared/modals/SendEmailModal";
import AddTaskModal from "shared/modals/AddTaskModal";
import AddEventModal from "shared/modals/AddEventModal";
import types from "actions/call/types.js";
import office_view from 'actions/office_view';

const CHANNELS = {
    permission: 'perm',
    chat: 'chat',
    notification: 'notify',
    voip: 'voip',
}

const WebsocketConnection = () => {
    const dispatch = useDispatch();
    // const callDetails = useSelector(state => state.call.callDetails);
    const { customer_id, user_id } = useSelector(state => state.auth);
    const [ messageQueue, setMessageQueue ] = useState([])
    let messageQueueCheck = [];

    const getTokenWS = async () => {
        let { ws_token } = await dispatch(authActions.check()) || {};

        //check whether we persit conneciton token
        if(!ws_token){
            try {
                const tokenInfo = await axiosInstance.instance1.get(`${routes.WEBSOCKET}/connect`)
                ws_token = tokenInfo.data.feToken;
            } catch(e){
                console.log("|+|Websocket Connection Failure",e)
            }
        } 
        return ws_token
    }

    const centrifuge = new Centrifuge(`${basePath.VIEW_HOST}`, {
        debug: true,
        getToken: () => getTokenWS()
    });

    const onDismissNotification = (id) => {
        let incomingNotification = messageQueueCheck;
        setTimeout(() => {
            const indexToRemove = incomingNotification.findIndex(notification => notification.notification_id === id);
            incomingNotification.splice(indexToRemove, 1);

            const notification = document.getElementsByClassName(`Notification--${id}`);
            if(notification.length === 1){
                notification[0].remove();
                setMessageQueue(incomingNotification);
            }
        }, (7000)); //Time Interval to Dismiss incoming message
    }

    useEffect(() => {
            if(!customer_id && !user_id){
                return;
            }

            centrifuge.on('connect', (context) => {
                console.log("|+|Connect Context",context);
            });
            centrifuge.on('error', (e) => {
                console.log('|+|Centrifuge Error: ',e);
            });
            centrifuge.on('disconnect', (context) => {
                console.log("|+|Centrifuge Disconnect",context);
            })
            
            centrifuge.connect()

            //notifications
            const notificationSub = centrifuge.newSubscription(`${CHANNELS.notification}_${customer_id}_${user_id}`);
            notificationSub.on('subscribing', (msg) => {
                // console.log('|+|Notification Subscribing...', msg);
            });
            notificationSub.on('subscribed', (msg) => {
                console.log('|+|Notification Subscribed', msg);
            });
            notificationSub.on('unsubscribed', (msg) => {
                // console.log('|+|Notification Unsubscribed', msg);
            });
            notificationSub.on('publication', (msg) => {
                const message = msg.data;
                dispatch(notificationsActions.hasUnReadNotifications());
                dispatch(notificationsActions.onReadNotificationCenter());
                setMessageQueue(prevQueue => [...prevQueue, message]);
                messageQueueCheck.push(message) 
                onDismissNotification(message.notification_id);
            });
            notificationSub.subscribe();

            //permissions
            const permissionsSub = centrifuge.newSubscription(`${CHANNELS.permission}_${customer_id}_${user_id}`);
            permissionsSub.on('subscribing', (msg) => {
                // console.log('|+|Permission Subscribing...', msg);
            });
            permissionsSub.on('subscribed', (msg) => {
                console.log('|+|Permission Subscribed', msg);
            });
            permissionsSub.on('unsubscribed', (msg) => {
                // console.log('|+|Permission Unsubscribed', msg);
            });
            permissionsSub.on('publication', (msg) => {
                switch(msg.data.type) {
                    case 'update':
                        //get appPermissions
                        dispatch(permissionsActions.readPermissions());
                        //get userPermissions
                        dispatch(roleActions.readUserRole(msg.data.access_role_id));
                        //Refresh userProfile 
                        dispatch(userActions.viewUserProfile(msg.data.user_id));
                        break;
                    case 'delete':
                        //Check authentication and kick user out
                        dispatch(authActions.check()); 
                        break;
                    default:
                        console.log("|+|Message Error: ", msg)
                        break;
                } 
            });
            permissionsSub.subscribe();

            //VOIP
            const voipSub = centrifuge.newSubscription(`${CHANNELS.voip}_${customer_id}`);
            voipSub.on('subscribing', (msg) => {
                // console.log('|+|VOIP Subscribing...', msg);
            });
            voipSub.on('subscribed', (msg) => {
                console.log('|+|VOIP Subscribed', msg);
            });
            voipSub.on('unsubscribed', (msg) => {
                // console.log('|+|VOIP Unsubscribed', msg);
            });
            voipSub.on('publication', (msg) => {
                console.log("|+|VOIP_Message: ", msg);
                switch (msg.data.event) {
                    case 'channel_bridge':
                        dispatch({type: types.CONNECT_CALL, call: msg.data.message, leg: msg.data.leg_uuid})
                        break;
                    case 'channel_hangup':
                        dispatch({type: types.END_CALL, call: msg.data.message, leg: msg.data.leg_uuid})
                        break;
                    case 'channel_create':
                        dispatch({type: types.NEW_CALL, call: msg.data.message, leg: msg.data.leg_uuid})
                        break;
                    case 'call_recording':
                        dispatch(office_view.callRecording('CALL_RECORDING', msg.data.data));
                        break;
                    default:
                        console.log("|+|Message Error: ", msg)
                        break;
                }
            });
            voipSub.subscribe();

        //unMount
        return () => {
            if(centrifuge){
                centrifuge.disconnect();
                centrifuge.setToken(null)
                console.log("|+|Centrifuge Disconnect")
            }
        }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customer_id, user_id]);

    useEffect(() => {
        if (messageQueue.length === 0) {
          return; // Return if no messages to process.
        }
        const message = messageQueue[messageQueue.length - 1];
        NotificationInfo.alert(messageQueue, () => onOpenNotification(message.external_id, message.notification_type, message.notification_id), true);

      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [messageQueue]);

    const {
        onToggleModal,
        taskModalOpen,
        setTaskModalOpen,
        control,
        watch,
        isValid,
        isDirty,
        trigger,
        setValue,
        getValues,
        reset,
        isAllowedToModify,
        eventBeingEdited,
        handleSubmitEvent,
        resetField,
        taskControl,
        taskWatch,
        taskIsValid,
        taskIsDirty,
        taskTrigger,
        taskSetValue,
        taskGetValues,
        taskClearErrors,
        taskSetError,
        taskReset,
        taskBeingEdited,
        loadTask,
        addTask,
        state,
        setState,
        onDeleteTask,
        onUpdateTask,
        handleSubmit,
        task_labels,
        organizer_id,
        organizer,
        organizer_email,
        eventModalOpen,
        emailModalOpen,
        emailData,
        person_id,
        onDelete,
        onUpdate,
        setEventModalOpen,
        onOpenNotification,
        current_user,
        setNotificationContactName
    } = NotificationPopupModals();

    return (
        <>
            <SendEmailModal
                open={emailModalOpen}
                onClose={() => { if(emailModalOpen){onToggleModal("email", 0)} }}
                contact_id={person_id}
            />
            <AddTaskModal
                open={taskModalOpen}
                onClose={() => setTaskModalOpen(false)}
                state={state}
                control={taskControl}
                watch={taskWatch}
                isValid={taskIsValid}
                isDirty={taskIsDirty}
                trigger={taskTrigger}
                setValue={taskSetValue}
                getValues={taskGetValues}
                clearErrors={taskClearErrors}
                setError={taskSetError}
                reset={taskReset}
                taskBeingEdited={taskBeingEdited}
                loadTask={loadTask}
                addTask={addTask}
                setState={setState}
                onDelete={onDeleteTask}
                onUpdate={onUpdateTask}
                task_labels={task_labels}
                organizer_id={organizer_id}
                organizer={organizer}
                organizer_email={organizer_email}
                handleSubmit={handleSubmit}
                onRefresh={() => {
                    setTaskModalOpen(false);
                }}
                email={emailData} 
            />
            <AddEventModal
                open={eventModalOpen}
                onClose={() => setEventModalOpen(false)}
                onEdit={handleSubmitEvent(onUpdate)}
                onDeleteEvent={onDelete}
                eventBeingEdited={eventBeingEdited}
                selected_users={[current_user]}
                isAllowedToModify={isAllowedToModify}
                control={control}
                watch={watch}
                isValid={isValid}
                isDirty={isDirty}
                trigger={trigger}
                setValue={setValue}
                getValues={getValues}
                reset={reset}
                resetField={resetField}
                setNotificationContactName={setNotificationContactName}
                displayAllContacts
            />
        </>
    )
}

export default WebsocketConnection;

