const initialState = {
    calls: {},
    isReadingCalls: false,
    isHangingUp: false,
    
    incomingCalls: [],
    recentCalls: [],
    numberOfConferenceRooms: 0,
    departments: [],
    extensions: [],
    queues: [],
    callerInActionsView: null,
    actionsViewVisible: false,
    isActiveCall: false,

    currentCall: null,
    isReadingCreds: false,
    creds: {},
    callState: 'idle',
    contactLeg: null, 
    userLeg: null,
    currentPBX: null,
    pbx: null,
    duration: 0,
    callRecordings: [],
    currentCallRecording: null,
    currentCallPhoneNumber: null,
    currentCallTicker: 0

}

export default initialState;
