//import React, { useEffect, useState } from 'react';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Phone } from './softphone';

export const Softphone = () => {
    let softphone = useRef({});

    const { creds, isReadingSoftphones } = useSelector((state) => {  
        return {
            creds: state.officeView.creds,
            isReadingSoftphones: state.officeView.isReadingSoftphones
        }
    });

    useEffect(() => {
        if (creds.username && ! softphone?.phone?.config ) {
            softphone.current = Phone(creds,{}, {})
        }

    }, [isReadingSoftphones, creds]);

    return softphone;
}