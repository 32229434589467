import React, { useState } from 'react';
import { Icon, Header } from 'semantic-ui-react';


import './index.scss';

const PhoneButton = ({ onClick, text, icon, iconColor, disabled, ...otherProps }) => {
    const [isClicked, setIsClicked] = useState(false);
    
    const onClickButton = () => {
        setIsClicked((prevState) => !prevState);
        onClick();
    }

    return (
        <button {...otherProps} onClick={onClickButton} className={`PhoneButton${isClicked ? "--active" : ""}`} disabled={disabled}>
            <div><Icon size="large" className={`${icon} color--${iconColor}`} /><Header className={`${isClicked ? "Header--active" : ""}`} style={{fontWeight: 300, marginLeft: 10, marginTop: 0, display: 'inline-block', verticalAlign: 'bottom'}} as="h3">{text}</Header></div>
        </button>
    );
}

export default PhoneButton;
