import React from 'react';
import './index.scss';
import DialButton from './DialButton';

const DialButtonRow = ({ buttons = [], disabled, onChange, name, value, onKeyPress }) => {
    return (
        <div className='OfficeViewDialPad__dialRow'>
            {buttons.map(({ symbol, subtext }) => (
                <DialButton symbol={symbol} subtext={subtext} disabled={disabled} onClick={(symbol) => {
                    onChange({ target: { name, value: `${value}${symbol}` } });
                    onKeyPress(symbol);
                }} />
            ))}
        </div>
    )
};

export default DialButtonRow;